<template>
   <v-app>
      <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')" />
          </v-app-bar-title>
          <v-spacer/>
          <v-btn icon @click="$auth.validatePage()"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>
      <v-main >
        <v-container color="transparent">
          <v-row align="center" justify="center">
            <v-col cols="12" class="d-flex flex-column justify-center align-center">
            <v-card width="1544" height="800" style="border-radius: 8px">
                <v-card-text class="pa-0 ma-0 fill-height">
                  <v-row dense class="fill-height">
                    <v-col cols="6" class="fill-height">
                      <v-card class="fill-height" flat>
                       <AzureMap  class="azure-map"  :center="[long, lat]" :zoom="zoom" @ready="setMap">
        <AzureMapDataSource>
      <AzureMapPoint :longitude="long" :latitude="lat"/>
      <AzureMapSymbolLayer :options="targetSymbolLayerOptions"/>
    </AzureMapDataSource>
     </AzureMap>
                      </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card flat >
                    <v-card-text>
                      <div  class="text-h4 primary--text font-weight-bold" >ติดต่อเรา</div>
                      <div  class="text-h5 pb-5 black--text font-weight-bold">บริษัท เน็กซเตอร์ ลีฟวิ่ง จำกัด</div>
                      <div  class="text-h5 pb-5 black--text" >เลขที่ 1 ถ.ปูนซิเมนต์ไทย แขวงบางซื่อ เขตบางซื่อ กรุงเทพ 10800 vwellsupport@scg.com</div>
<TextField :title="'ชื่อ-นามสกุล'" dense :required="true"/>
<TextField :title="'Email'" dense :required="true"/>
<TextField :title="'เบอร์ติดต่อกลับ'" dense/>
<TextArea :title="'ข้อความถึงเรา'" dense/>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" x-large block>ส่งข้อมูล</v-btn>
                </v-card-actions>
                </v-card>
                </v-col>
                </v-row>
                </v-card-text>
            </v-card>
          </v-col>
          </v-row>
        </v-container>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
   </v-app>
</template>

<script>
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer
} from 'vue-azure-maps'
import TextField from '@/components/common/TextField'
import TextArea from '@/components/common/TextArea'
export default {
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer,
    TextField,
    TextArea
  },
  data () {
    return {
      data: '',
      loading: false,
      agree: false,
      selectedTenant: null,
      lat: 13.806432,
      long: 100.523422,
      map: null,
      zoom: 14,
      targetSymbolLayerOptions: {
        iconOptions: {
          image: 'pin-red'
        }
      }
    }
  },
  methods: {
    setMap (mapEvent) {
      this.map = mapEvent.map
      this.map.resize()
    },
    fetchData () {
      // this.data = JSON.parse(sessionStorage.getItem('Tenant'))
      // this.selectedSite = JSON.parse(sessionStorage.getItem('Tenant')).site[0]
    },
    setTenant () {
      // sessionStorage.setItem('Site', JSON.stringify(this.selectedSite))
      // this.$auth.validatePage()
    }
  },
  created () {
    this.fetchData()
    // this.$watch(
    //   () => this.$route.params,
    //   () => {
    //     this.fetchData()
    //   },
    //   // fetch the data when the view is created and the data is
    //   // already being observed
    //   { immediate: true }
    // )
  }
}
</script>

<style  scoped>
.azure-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
